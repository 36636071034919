import React, { Fragment } from 'react'

/** COMPONENTS */
import { CardMontre } from '../../components'

const CardsMontres = ({ title, allMontres }) => {
	return (
		<Fragment>
			<div className="container-fluid my-7">
				{/* TITRE */}
				<h2 className="text-uppercase mb-5 text-center text-secondary">{title}</h2>
				{/* MONTRES */}
				<div className="row">
					{allMontres.montres.map(montre => (
						// Map creat an array - loop for all the montre in montres of CardMontre.js
						<CardMontre
							brand={montre.brand}
							id={montre.id}
							montre={montre}
							image={require('../../img/montres/' + montre.image)}
							info={montre.info}
							prixReel={montre.prixReel}
							prixActuel={montre.prixActuel}
						/>
					))}
				</div>
			</div>
		</Fragment>
	)
}
export default CardsMontres
