import React, { Component, Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

/** Routing */
import Routing from './components/Features/RoutesApp/RoutIng'

/** COMPONENTS */
import { NavBar, Footer } from './components'

export default class App extends Component {
	render() {
		return (
			<Fragment>
				<div id="body">
					{/* like index.js - default page that are implemented in all pages */}

					<Router>
						{/* NAVBAR */}
						<NavBar />

						{/* CONTENT */}

						{/* ROUTER */}
						{/* Select page for each path */}

						<Switch>
							<Route component={Routing} />
						</Switch>
					</Router>

					{/* FOOTER */}
					<Footer />
				</div>
			</Fragment>
		)
	}
}
