import React, { Component, Fragment } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { NavLink } from 'react-router-dom'

/**  IMAGES  */
import { logo } from '../../img'

export default class NavBar extends Component {
	scrollToTop() {
		//scroll at top of the page onClick
		scroll.scrollToTop()
	}
	render() {
		return (
			<Fragment>
				{/* CSS -> NavBar.SCSS !!! */}
				<div id="MainNav">
					<nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top AnimationNav">
						<div className="container-fluid">
							<NavLink className="navbar-brand" to="/" onClick={this.scrollToTop}>
								<img src={logo} alt="home" height="40" />
							</NavLink>
							<div className="text-center form-inline" id="navbarNav">
								<NavLink
									className="nav-link"
									to="/montres"
									activeStyle={{
										color: '#c2a46e',
									}}
									onClick={this.scrollToTop}
								>
									<h2 className="my-auto">
										<span className="icon-montre" />
									</h2>
								</NavLink>
								<NavLink
									className="nav-link"
									to="/research"
									activeStyle={{
										color: '#c2a46e',
									}}
									onClick={this.scrollToTop}
								>
									<h2 className="my-auto">
										<span className="icon-loupe" />
									</h2>
								</NavLink>
								<NavLink
									className="nav-link"
									to="/admin"
									activeStyle={{
										color: '#c2a46e',
									}}
									onClick={this.scrollToTop}
								>
									<h2 className="my-auto">
										<span className="icon-admin" />
									</h2>
									{/* <img id="White" src={admin} alt="admin" height="20" /> */}
								</NavLink>
							</div>
						</div>
					</nav>
					<div className="fixed-top border-bottom border-primary Border AnimationNav" />
				</div>
			</Fragment>
		)
	}
}
