import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'

export default class CardMontreFullWidth extends Component {
	//scroll at top of the page onClick
	scrollToTop() {
		scroll.scrollToTop()
	}
	render() {
		return (
			<Fragment>
				<Link to={this.props.link} onClick={this.scrollToTop} className="NoDecoration">
					<img
						className="img-fluid FullImage"
						src={this.props.image}
						alt={this.props.image}
					/>
					<div className="bg-light" height="50" id="fullimage">
						<div className="container-fluid py-4">
							<div className="row">
								<div className="col">
									{/* INFORMATIONS */}
									<div className="row card-body px-1 py-2">
										<div className="col MinWidthSmall">
											{/* BRAND NAME */}
											<h2 className="font-weight-bold card-title py-2">
												Rolex
											</h2>
											{/* DESCRIPTION */}
											<h3 className="text-secondary card-text LineHeight ">
												DATEJUSTE 22, Oyster Perpetual
											</h3>
										</div>
										{/* CHF */}
										<div className="col text-right">
											{/* %%%%%%% */}
											<h2 className="font-weight-bold text-primary py-2">
												-20%
											</h2>
											{/* PRIX REEL */}
											<h3 className="text-secondary mb-1">
												<s>22 700 CHF</s>
											</h3>
											{/* PRIX ACTUEL */}
											<h3 className="text-primary m-0">20 320 CHF</h3>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Link>
			</Fragment>
		)
	}
}
