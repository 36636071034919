import React, { Fragment } from 'react'

const MontreSeule = props => {
	const montre = props.location.state.montre
	return (
		<Fragment>
			<div className="container-fluid NavMargin" id="MontreSeule">
				<div className="my-8">
					<div className="row">
						{/* BRAND NAME */}
						<div className="col-lg-6">
							<h1 className="font-weight-bold">{montre.brand}</h1>
							{/* INFORMATIONS */}
							<h2 className="text-secondary LineHeight">{montre.info}</h2>
							{/* DESCRIPTION */}
							<h3 className="my-5 ">{montre.montre.description}</h3>
							{/* INFOS */}
							<div className="my-5">
								{/* ETAT */}
								<ul className="list-inline">
									<li className="list-inline-item px-2 Icons">
										<span className="icon-star" />
									</li>
									<li className="list-inline-item px-2">
										<h3>{montre.montre.etat}</h3>
									</li>
								</ul>
								{/* CONTENU */}
								<ul className="list-inline">
									<li className="list-inline-item px-2 Icons">
										<span className="icon-box" />
									</li>
									<li className="list-inline-item px-2">
										<h3>{montre.montre.contenu}</h3>
									</li>
								</ul>
								{/* %%%%%%% */}
								<ul className="list-inline">
									<li className="list-inline-item px-2 Icons">
										<span className="icon-percent" />
									</li>
									<li className="list-inline-item px-2">
										<h3>- 30 %</h3>
									</li>
								</ul>
								{/* CHF */}
								<ul className="list-inline">
									<li className="list-inline-item px-2 Icons">
										<span className="icon-price" />
									</li>
									<li className="list-inline-item px-2">
										<h3>
											{/* PRIX REEL */}
											<span className="mr-3">
												<s> CHF {montre.montre.prixReel}.-</s>
											</span>{' '}
											{/* PRIX ACTUEL */}
											<span className="text-primary">
												CHF {montre.montre.prixReel}.-
											</span>
										</h3>
									</li>
								</ul>
								{/* BUTTONS */}
								<div className="my-5">
									<div className="list-inline-item my-1">
										<button
											type="button"
											className="btn btn-info btn-lg btn-block"
										>
											<h2 className="text-white mt-2 mx-3">
												Contacter le vendeur
											</h2>
										</button>
									</div>
									<div className="list-inline-item my-1">
										<button
											type="button"
											className="btn btn-primary btn-lg btn-block"
										>
											<h2 className="text-white mt-2 mx-5">Acheter</h2>
										</button>
									</div>
								</div>
							</div>
						</div>
						{/* IMAGE */}
						<div className="col">
							<div className="mx-auto">
								<img
									className="img-fluid border-0 card BigImg"
									src={require('../../img/montres/' + montre.montre.image)}
									alt={montre.montre.image}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}
export default MontreSeule
