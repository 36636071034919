import React, { Component, Fragment } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { NavLink } from 'react-router-dom'

export default class NavBarSecondAdmin extends Component {
	scrollToTop() {
		//scroll at top of the page onClick
		scroll.scrollToTop()
	}
	render() {
		return (
			<Fragment>
				{/* CSS -> NavBar.SCSS !!! */}
				<div id="SecondNav">
					<nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top AnimationNav2 p-0">
						<div className="container-fluid justify-content-center my-auto">
							<div className="text-center form-inline" id="navbarNav">
								<NavLink
									className="nav-link mx-2"
									to="/commandes"
									activeStyle={{
										fontWeight: 'bold',
										color: 'black',
									}}
									onClick={this.scrollToTop}
								>
									<h2 className="Hide list-inline-item my-auto pb-3">
										Commandes
									</h2>
									<h2 className="list-inline-item my-auto">
										<span className="icon-commandes" />
									</h2>
								</NavLink>
								<NavLink
									className="nav-link mx-2"
									to="/admin"
									activeStyle={{
										fontWeight: 'bold',
										color: 'black',
									}}
									onClick={this.scrollToTop}
								>
									<h2 className="Hide list-inline-item my-auto pb-3">
										Informations
									</h2>
									<h2 className="list-inline-item my-auto">
										<span className="icon-infos" />
									</h2>
								</NavLink>
								<NavLink
									className="nav-link mx-2"
									to="/annonces"
									activeStyle={{
										fontWeight: 'bold',
										color: 'black',
									}}
									onClick={this.scrollToTop}
								>
									<h2 className="Hide list-inline-item my-auto pb-3">Annonces</h2>
									<h2 className="list-inline-item my-auto">
										<span className="icon-annonces" />
									</h2>
								</NavLink>
							</div>
						</div>
					</nav>
				</div>
			</Fragment>
		)
	}
}
