import React, { Component, Fragment } from 'react'

/** COMPONENTS */
import { CardCommande, NavBarSecondAdmin } from '../../../components'

/** STATIC DATA */
import { staticDataCommandes } from '../../../json'

export default class Commandes extends Component {
	render() {
		return (
			<Fragment>
				<NavBarSecondAdmin />
				{/* CSS -> ADMIN.SCSS !!! */}
				<div className="container-fluid NavMargin ScreenCenter" id="commandes">
					{/* Mes Commandes */}
					<div className="row my-6">
						{staticDataCommandes.commandes.map(commande => (
							// Map creat an array - loop for all the commande in commandes of CardCommande.js
							<CardCommande
								noCommande={commande.noCommande}
								statut={commande.statut}
								dateCommande={commande.dateCommande}
								brand={commande.brand}
								link={commande.link}
								image={require('../../../img/montres/' + commande.image)}
								info={commande.info}
								prixReel={commande.prixReel}
								prixActuel={commande.prixActuel}
							/>
						))}
					</div>
				</div>
			</Fragment>
		)
	}
}
