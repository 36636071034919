import React, { Component, Fragment } from 'react'

/** STATIC DATA */
import { staticDataMontres } from '../../json'

/** COMPONENTS */
import { CardsMontres, NavBarSecondMontres } from '../../components'

export default class TenTen extends Component {
	render() {
		return (
			<Fragment>
				<NavBarSecondMontres />
				<CardsMontres allMontres={staticDataMontres} />
			</Fragment>
		)
	}
}
