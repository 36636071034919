import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'

/** COMPONENTS */
import {
	NotFound,
	TenTen,
	Montres,
	Admin,
	Research,
	MontreSeule,
	Commandes,
	Annonces,
	Marques,
} from '../../index'

const Routing = props => {
	return (
		<Fragment>
			<Switch>
				{/* MENU - NAVBAR */}
				<Route exact path="/" component={TenTen} />
				<Route exact path="/montres" component={Montres} />
				<Route exact path="/montres-homme" component={Montres} />
				<Route exact path="/montres-femme" component={Montres} />
				<Route exact path="/marques" component={Marques} />
				<Route exact path="/research" component={Research} />
				{/* ADMIN */}
				<Route exact path="/admin" component={Admin} />
				<Route exact path="/commandes" component={Commandes} />
				<Route exact path="/annonces" component={Annonces} />

				<Route exact path="/:brand/:id" component={MontreSeule} />

				{/* 404 */}
				<Route path="" component={NotFound} />
			</Switch>
		</Fragment>
	)
}

export default Routing
