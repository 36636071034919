import React, { Component, Fragment } from 'react'

/** COMPONENTS */
import { NavBarSecondAdmin } from '../../../components'

export default class Admin extends Component {
	render() {
		return (
			<Fragment>
				<NavBarSecondAdmin />
				<div className="container NavMargin ScreenCenter" id="admin">
					<div className="my-6">
						<div className="row">
							{/* GROUP */}
							<div className="col Group">
								{/* ADMIN */}
								<div className="row mx-auto">
									<div className="col my-auto ColButton">
										<button type="button" className="btn btn-light">
											<h1 className="mx-2 mt-2">
												<span className="icon-admin" />
											</h1>
											<h2 className="font-weight-bold mt-2">Modifier</h2>
										</button>
									</div>
									<div className="col text-left ColInfo my-auto">
										<h4 className="text-uppercase text-info font-weight-bold">
											Nom
										</h4>
										<h3 className="">Jack Sparrow</h3>
										<h4 className="text-uppercase text-info font-weight-bold mt-4">
											Téléphone
										</h4>
										<h3 className="">+41 79 922 22 22</h3>
									</div>
								</div>
							</div>
							{/* GROUP */}
							<div className="col Group">
								{/* MAIL */}
								<div className="row mx-auto">
									<div className="col my-auto ColButton">
										<button type="button" className="btn btn-light">
											<h1 className="mx-2 mt-2">
												<span className="icon-mail" />
											</h1>
											<h2 className="font-weight-bold mt-2">Modifier</h2>
										</button>
									</div>
									<div className="col text-left ColInfo my-auto">
										<h4 className="text-uppercase text-info font-weight-bold">
											Mail
										</h4>
										<h3 className="">jack@gmail.ch</h3>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							{/* GROUP */}
							<div className="col Group">
								{/* HOME */}
								<div className="row mx-auto">
									<div className="col my-auto ColButton">
										<button type="button" className="btn btn-light">
											<h1 className="mx-2 mt-2">
												<span className="icon-home" />
											</h1>
											<h2 className="font-weight-bold mt-2">Modifier</h2>
										</button>
									</div>
									<div className="col text-left ColInfo my-auto">
										<h4 className="text-uppercase text-info font-weight-bold">
											Adresse
										</h4>
										<h3 className="">Rue du Marché 1</h3>
										<h3 className="">1820 Montreux</h3>
									</div>
								</div>
							</div>
							<div className="col Group">
								{/* LOCK */}
								<div className="row mx-auto">
									<div className="col my-auto ColButton">
										<button type="button" className="btn btn-light">
											<h1 className="mx-2 mt-2">
												<span className="icon-lock" />
											</h1>
											<h2 className="font-weight-bold mt-2">Modifier</h2>
										</button>
									</div>
									<div className="col text-left ColInfo my-auto">
										<h4 className="text-uppercase text-info font-weight-bold">
											Mot de Passe
										</h4>
										<h3 className="text-uppercase">
											<i className="fas fa-circle fa-xs" />{' '}
											<i className="fas fa-circle fa-xs" />{' '}
											<i className="fas fa-circle fa-xs" />{' '}
											<i className="fas fa-circle fa-xs" />{' '}
											<i className="fas fa-circle fa-xs" />
										</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}
