import React, { Component, Fragment } from 'react'

export default class Research extends Component {
	render() {
		return (
			<Fragment>
				<div className="ScreenCenter container" id="Research">
					<form className="form-inline">
						<h1 className="text-primary mt-3 mx-auto px-3">
							<span className="icon-loupe" />
						</h1>
						<input
							className="form-control form-control-lg mr-sm-2"
							type="search"
							aria-label="Search"
						/>
					</form>
				</div>
			</Fragment>
		)
	}
}
