import React, { Component, Fragment } from 'react'

/**  IMAGES  */
import { electronic, gousset, mecanique, squelette } from '../../img'

export default class AllCategoriesIcons extends Component {
	render() {
		return (
			<Fragment>
				<div className="container text-center my-7 text-secondary" id="Categories">
					<h2 className="text-uppercase mb-5">Style</h2>
					<div className="row align-bottom">
						<div className="col MinWidth">
							<img className="my-5" src={mecanique} alt="mécanique" height="112" />
							<h2 className="">Mécanique</h2>
						</div>
						<div className="col MinWidth">
							<img className="my-5" src={electronic} alt="électronic" height="112" />
							<h2 className="">Électronique</h2>
						</div>
						<div className="col MinWidth">
							<img className="my-5" src={squelette} alt="squelette" height="112" />
							<h2 className="">Squelette</h2>
						</div>
						<div className="col MinWidth">
							<img className="my-5" src={gousset} alt="gousset" height="112" />
							<h2 className="">Gousset</h2>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}
