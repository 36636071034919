import React, { Component, Fragment } from 'react'

/** COMPONENTS */
import { CardMontre, NavBarSecondAdmin, NavBarThirdAdmin } from '../../../components'

/** STATIC DATA */
import { staticDataAnnonces } from '../../../json'

export default class Annonces extends Component {
	render() {
		return (
			<Fragment>
				<NavBarSecondAdmin />
				<NavBarThirdAdmin />
				{/* CSS -> ADMIN.SCSS !!! */}
				<div className="container-fluid ThirdNavMargin ScreenCenter" id="annonces">
					<div className="py-6">
						{/* Buttons */}
						<div className="text-center">
							<button type="button" className="btn btn-info btn-lg mb-6">
								<h2 className="text-white mt-2 mx-3">Ajouter une annonce</h2>
							</button>
						</div>
						{/* Mes Annonces */}
						<div className="row">
							{staticDataAnnonces.annonces.map(annonce => (
								// Map creat an array - loop for all the commande in commandes of CardCommande.js
								<CardMontre
									brand={annonce.brand}
									id={annonce.id}
									// montre={annonce}
									image={require('../../../img/montres/' + annonce.image)}
									info={annonce.info}
									prixReel={annonce.prixReel}
									prixActuel={annonce.prixActuel}
								/>
							))}
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}
