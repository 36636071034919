import React, { Component, Fragment } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
/*
* Install the Library - Styled-Components
* Open the integrated Terminal (Command + Shift + ^)
* $ yarn add styled-components
*/
const CardImage = styled.div`
	z-index: 1;
	display: block;
	width: 300px;
	height: 350px;
	background: #ffffff;

	background-image: url(${props => props.image});
	background-size: 300px;
	background-repeat: no-repeat;
	background-position: center;

	transition: 0.4s ease-in-out;
	-webkit-transition: 0.4s ease-in-out;
	-moz-transition: 0.4s ease-in-out;
	-ms-transition: 0.4s ease-in-out;
	-o-transition: 0.4s ease-in-out;

	transform: scale(1);
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);

	border-radius: 0.5rem !important;

	&:hover {
		border-radius: 0.5rem !important;

		cursor: pointer;

		transform: scale(1.1);
		-webkit-transform: scale(1.1);
		-moz-transform: scale(1.1);
		-ms-transform: scale(1.1);
		-o-transform: scale(1.1);
	}
	@media (max-width: 800px) {
	}
`
export default class CardCommande extends Component {
	//scroll at top of the page onClick
	scrollToTop() {
		scroll.scrollToTop()
	}
	percentPriceProduct = () => {
		const { prixActuel, prixReel } = this.props
		const pricePercent = prixActuel * 100 / prixReel
		return Math.round(pricePercent - 100)
	}
	render() {
		const {
			image,
			brand,
			link,
			info,
			prixReel,
			prixActuel,
			noCommande,
			statut,
			dateCommande,
		} = this.props
		return (
			<Fragment>
				<div className="mx-auto" id="cardCommande">
					<div className="my-4 col" id="card">
						{/* LINK */}
						<Link to={link} onClick={this.scrollToTop} className="NoDecoration">
							<div className="CardBackground rounded">
								<div className="CardWidth mx-auto my-auto">
									{/* NO COMMANDE */}
									<h3 className="TextBackground card-text font-weight-bold LineHeight">
										No. de commande {noCommande}
									</h3>
									{/* DATE */}
									<h3 className="TextBackground card-text font-weight-bold LineHeight ">
										Passé le {dateCommande}
									</h3>
									{/* STATUT */}
									<h3 className="TextBackground card-text font-weight-bold LineHeight ">
										Statut {statut}
									</h3>
									{/* ------------- CARD récupérer l'ID !---------------------- */}
									{/* IMAGE */}
									<div className="ImageCard m-0 py-2">
										<CardImage image={image} />
									</div>
									{/* %%%%%%% */}
									<div className="overlay">
										<h4 className="font-weight-bold text-primary px-1 pb-0 mb-0">
											{this.percentPriceProduct()} %
										</h4>
									</div>
									{/* BRAND NAME */}
									<h3 className="font-weight-bold card-title pt-1 pb-0 mb-0 px-1 text-dark">
										{brand}
									</h3>
									{/* INFORMATIONS */}
									<div className="row card-body px-1 py-2">
										{/* DESCRIPTION */}
										<div className="col MinWidthSmall pr-1">
											<h4 className="text-secondary card-text LineHeight ">
												{info}
											</h4>
										</div>
										{/* CHF */}
										<div className="col text-right pl-1">
											{/* PRIX REEL */}
											<h4 className="text-secondary mb-1">
												<s>{prixReel} CHF</s>
											</h4>
											{/* PRIX ACTUEL */}
											<h4 className="text-primary m-0">{prixActuel} CHF</h4>
										</div>
									</div>
								</div>
							</div>
						</Link>
					</div>
				</div>
			</Fragment>
		)
	}
}
