import React, { Component, Fragment } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { NavLink } from 'react-router-dom'

// NAVBAR - On Annonces Pages only

export default class NavBarThirdAdmin extends Component {
	scrollToTop() {
		//scroll at top of the page onClick
		scroll.scrollToTop()
	}
	render() {
		return (
			<Fragment>
				{/* CSS -> NavBar.SCSS !!! */}
				<div id="ThirdNav">
					<nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top AnimationNav3">
						<div className="container-fluid justify-content-center">
							<div className="text-center form-inline" id="navbarNav">
								<NavLink
									className="nav-link"
									to="/annonces"
									activeStyle={{
										fontWeight: 'bold',
										color: 'black',
									}}
									onClick={this.scrollToTop}
								>
									<h2 className="list-inline-item my-auto">Toutes</h2>
								</NavLink>
								<NavLink
									className="nav-link"
									to="/annonces-actives"
									activeStyle={{
										fontWeight: 'bold',
										color: 'black',
									}}
									onClick={this.scrollToTop}
								>
									<h2 className="list-inline-item my-auto">Actives</h2>
								</NavLink>
								<NavLink
									className="nav-link"
									to="/annonces-vendues"
									activeStyle={{
										fontWeight: 'bold',
										color: 'black',
									}}
									onClick={this.scrollToTop}
								>
									<h2 className="list-inline-item my-auto">Vendues</h2>
								</NavLink>
							</div>
						</div>
					</nav>
				</div>
			</Fragment>
		)
	}
}
