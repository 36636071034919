import React, { Component, Fragment } from 'react'

/**  IMAGES  */
import { logo } from '../../img'

/** STATIC DATA */
import { staticDataMontres } from '../../json'

/** COMPONENTS */
import { NavBarSecondMontres, AllCategoriesIcons, CardsMontres } from '../../components'

export default class TenTen extends Component {
	constructor(props) {
		super(props)
		this.state = {
			MontresTitle: 'Nouvelles offres',
		}
	}
	render() {
		const { MontresTitle } = this.state
		return (
			<Fragment>
				<NavBarSecondMontres />
				<div className="BG">
					<div className="text-center pt-6 container NavMargin img-fluid">
						<img className="my-5" src={logo} alt="logo" height="422" />
						<h5>TenTen</h5>
					</div>
					<AllCategoriesIcons />
				</div>
				<CardsMontres title={MontresTitle} allMontres={staticDataMontres} />
			</Fragment>
		)
	}
}
